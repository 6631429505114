.rbc-allday-cell {
  display: none;
}
.rbc-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.rbc-today {
  background-color: unset;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-event {
  background-color: #457f8c;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}
