body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.table tr.visible.transition {
  display: table-row !important;
}

.patient-tab > div.tabular.menu > a.item {
  flex: 1;
}

th.vaxdash-column {
  text-align: center !important;
  white-space: nowrap;
}

td.vaxdash-calendar-vaccines-column {
  left: 0;
  height: 46px;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-right: solid 1px rgba(34, 36, 38, 0.1);
  background-color: rgb(249, 250, 251);
}

tr.vaxdash-calendar-row {
  min-height: 45px;
  max-height: 45px;
}

td.vaxdash-cell {
  padding: 0px !important;
}

div.vaxdash-cell {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: flex-start;
  white-space: nowrap;
}

div.vaxdash-cell.vaccinated {
  justify-content: center;
}

div.vaxdash-cell > div.label.vaccination {
  white-space: nowrap;
  text-transform: capitalize;
}

div.vaxdash-cell.recommended-range {
  background-color: #5dbcd1;
}

div.vaxdash-cell.recommended-range.non-eligible {
  background-color: #e0f7f8;
}

div.vaxdash-cell.recommended-range.start {
  border-width: 1px;
  border-start-start-radius: 2rem;
  border-end-start-radius: 2rem;
}

div.vaxdash-cell.recommended-range.end {
  border-width: 1px;
  border-start-end-radius: 2rem;
  border-end-end-radius: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mrn-input > div > input {
  background-color: #f4f4f4 !important;
  font-weight: 700;
  min-width: 13rem;
  height: 1.6rem !important;
}

.sidebar-patient-search .results {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 460px;
}

.date-picker-popper {
  z-index: 30;
}

.toast-container {
  z-index: 9999 !important;
}

.fixed-support-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #467597;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-support-bubble:hover {
  background-color: #3a6889;
}

.fixed-support-bubble .bubble-support-icon {
  color: white;
  font-size: 24px;
}

.fixed-support-bubble2 {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 1000;
  background-color: #467597;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-support-bubble2:hover {
  background-color: #3a6889;
}

.fixed-support-bubble2 .bubble-support-icon {
  color: white;
  font-size: 24px;
}

.fixed-support-bubble-small {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #467597;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-support-bubble-small:hover {
  background-color: #3a6889;
}

.fixed-support-bubble-small .bubble-support-icon {
  color: white;
  font-size: 16px;
}

.fixed-support-bubble2-small {
  position: fixed;
  bottom: 55px;
  right: 10px;
  z-index: 1000;
  background-color: #467597;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-support-bubble2-small:hover {
  background-color: #3a6889;
}

.fixed-support-bubble2-small .bubble-support-icon {
  color: white;
  font-size: 16px;
}